import React, { Component } from "react"
import IndexApp from "./indexApp"
import { CookiesProvider } from 'react-cookie';
import {Helmet} from "react-helmet"

class IndexPage extends Component {
  render() {
    return (
      <CookiesProvider>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
        </Helmet>
        <IndexApp lang={`ja`} />
      </CookiesProvider>
    )
  }
}

export default IndexPage
